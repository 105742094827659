<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-row>
      <b-col
        v-for="(marketingProgram, key) in marketingPrograms"
        :key="key"
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div
              class="d-flex  align-items-center justify-content-between"
            >
              <div
                class="d-flex  justify-content-center  flex-column"
                style="gap:8px;"
              >
                <h4>{{ marketingProgram.marketing_program_name }}</h4>
                <h5 class=" m-0 ">
                  <span class="text-success"> {{ marketingProgram.mkt_program_count }} / {{ marketingProgram.target }} </span>  <span class="fontsize-sm"> {{ marketingProgram.mkt_program_percentage }}</span>
                </h5>
                <p> From: {{ marketingProgram.date_from }} To: {{ marketingProgram.date_to }} </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" />
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AgentMarketingProgramTrackerService } from '@/services'

export default {
  name: 'AgentMarketingProgramTracker',

  middleware: ['auth', 'agent'],

  metaInfo () {
    return {
      title: 'Marketing Program Tracker'
    }
  },

  data () {
    return {
      marketingPrograms: []
    }
  },

  mounted () {
    core.index()
    this.getMarketingPrograms()
  },

  methods: {
    async getMarketingPrograms () {
      await AgentMarketingProgramTrackerService.get().then(({ data }) => {
        this.marketingPrograms = data
      })
    }
  }
}
</script>
